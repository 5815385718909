.about-img{


    width: 400px;
    
}

.about-desc{

    text-align: justify;
}

.spacer{

    margin-top: 100px
}