.contacts .list-group-item{

    background-color: #fff !important;
    color: #000 !important;
}

.contacts svg{

   

    fill: yellow !important;
}

.icon-fill{

    path{
        fill:#F05A7E !important;
        width: 20px !important;
      height:20px !important;
      float:right !important;
      }

    }
.contact-btn{

    background-color: #F05A7E !important;
}

.about-img {

background-size: cover !important;

width: 100% !important;
height: 600px !important;
}

