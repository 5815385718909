.signin{

    width: 300px !important
}

.signin-btn{

    float:right
}


.loginForm{

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  align-self: center;

    margin-top: 20px !important;
    margin-bottom: 20px !important;

    

}