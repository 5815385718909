.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.our-text{

  text-align: justify;
}



svg{
  color:'red' !important; 
  height:50px !important;
  width:50px !important;
  fill:crimson;

}

.home-services{

  path{
    fill:#F05A7E !important;
  }
}

.services-link a{

  text-decoration: none !important;
  color: #fff !important;

}

.services-btn{
  fill:#F05A7E !important;
  float:right;
  margin-bottom: 24px;
  background-color: #F05A7E !important;
}
.btn-rita{

  background-color: #F05A7E !important;
  color:#fff;
  float: right;
}

.btn-tops{

  background-color: #F05A7E !important;
  color:#fff;
  
}
.services-btn a {

  text-decoration: none;
  color:#fff;
}
.services-heading{

  color: grey
}



div.appointment.container{

display: relative;
  background-image: url("./assets/appImage.jpg");


  height: 500px !important;

  width: 1200px;
 
  background-repeat: no-repeat, repeat;

  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover
}

.our-container{

  position: relative;;

}

.appointment-desc{

  position: absolute;
  top: 120px;
  bottom: 10px;
  left: 0;
  right: 0;

  margin:0 auto;

  text-align: center;
}


.app-p{

  font-size: 20px;
  font-family: 'Lato',
  sans-serif;
}

.bg-reviews{

  background-color: #EEEDEB !important;
  color: #000
}

.about-img{
  height: 150px !important;
  width:350px !important;
}

.about-btn{
  float: right;
}


.about-btn a{
  text-decoration: none;
  color:#fff;
}


 @media screen and (min-width: 576px) {
  .swiper-container {
    width: 576px;
  }
}

@media screen and (min-width: 768px) {
  .swiper-container {
    width: 768px;
  }
} 

.work-images{

  height:400px
}

.our-heading{

  font-family: 'Lato',
  sans-serif;

}

.our-desc{

  font-family: 'Lato',
  sans-serif;
}

.app-btn a{

  text-decoration: none;
  color: #fff;
  

}

.app-btn{
  float: right !important;
}


@media only screen and (max-width: 600px) {
 .about-img{

  /* //width: 100% !important */
 }



 div.card.border-0{

  width: 100% !important;
 }

 .carousel-item img{
  width: 100% !important;
  height: 250px;
}
.navbar-toggler{

  background-color:  #ea6a84 !important;
}
.my-top{
  text-align: center !important;
  
}
.top-address{

  display: none !important;
}

.top-clock{

  display: none !important;
}

.top-phone{


  text-align: center !important;
  display: block !important;
 
}
.quick-links{

  justify-content: left !important;

 text-align: left;
 float: left;

}

.top-lock{

  flex-direction: row !important;
  justify-content: space-between;
  justify-content: center;
  justify-content: space-evenly;
}

.app-form{

  width: 100% !important
}

.bottom-dec {

  background-color:#F05A7E !important;
  border-top: 0.2px solid #EEEEEE !important;
}

.appointment-desc{

  top:90px !important;
}

.bottom-hours{

  margin-top: 18px !important;
  border-bottom: 1px solid red ;
  margin-bottom: 12px;
  padding: 18px

}

.container{
  --bs-gutter-x: 0.2rem !important
}
}


.services-svg{

  width: 70px;

  margin: auto;

 



}

.bg-reviews{

  background-color: #EEEEEE !important;
}