.footer-h{
    
    color:antiquewhite;
    justify-content: 'center';
    justify-self: center;
    align-items: center;
   
}

.footer-container{

    max-height: 60px;
    min-height: 50px;

   
    color: #fff !important;
    text-decoration: none !important;
    background-color: #F05A7E !important;
   
    text-align: center;
  
}


.appimage{

    width: 100%;
    height: 70px;
}

.footer-bg{

    background-color:#151515;
    color:#fff;
    font-family: "Lato", sans-serif;
    font-weight: 900;
    color: #fff !important;
    text-decoration: none !important;

    
  
}





.footer {
 
    left: 0;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: center;
    font-style: normal;
 }

.bottom-icon {

    width: 20px !important;
    height: 20px !important;
    color:crimson;
    margin-right: 12px;
  

}

.bottom-lists{

    /* display: flex;
    justify-content: center;
    flex-direction: row; */
}

.bottom-list a {

    text-decoration: none !important;
    color:#fff
}

a{

    color: #fff !important;
    text-decoration: none !important;
   
}

Footer{

    height: 100vh
}




.bottom-hours .list-group .list-group-item {
    background-color: #151515 !important ;
    color: #fff  !important;
    }
    
    
    .visit .list-group .list-group-item {
        background-color: #151515 !important ;
        color: #fff  !important;
        }


        .footer-container{

            height: 100vh !important;
            background-color: #F05A7E !important;
    
        }


        .bottom-icon {
            width: 20px !important;
            height: 20px !important;
            color: #F05A7E;
            margin-right: 12px;
            }

            .footer-row{

                background-color: #F05A7E ;
            }